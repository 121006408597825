import React, { useState, useEffect } from "react";
import "../styles/login.scss";
import { GoogleAuthProvider } from "firebase/auth";
import { Button, Container, Typography } from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import ClickableCard from "../components/ClickableCard";
const Login = () => {
  const {
    user,
    getAuthenticatedUser,
    loginWithProvider,
    createCustomToken,
    setIsLoadingUser,
  } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (user) {
      handleRedirect();
    }
  }, [user]);

  const handleRedirect = () => {
    console.info("User is authenticated");
    // Redirect to the original URL or default to home
    const redirectUrl = new URLSearchParams(location.search).get("redirect");

    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      const { from } = location.state || { from: { pathname: "/" } };
      navigate(from);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoadingUser(true);
    try {
      const result = await loginWithProvider(new GoogleAuthProvider());
      const user = result.user;
      const idToken = await user.getIdToken();
      await createCustomToken({ idToken, email: user.email });
      await getAuthenticatedUser();
    } catch (error) {
      setIsLoadingUser(false);
      console.error(error);
      setError(error.code);
      navigate("/access-denied");
    } finally {
      setIsLoadingUser(false);
    }
  };

  return (
    <div className="login-container-root">
      <Container maxWidth="lg">
        <div className="login-container-box">
          <div className="login-heading">Welcome to Thompson Spencer</div>
          <div className="login-button-container">
            <ClickableCard handleOnClick={handleLogin} title={"Sign in"} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Login;
