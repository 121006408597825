import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import PrivateRoute from "./components/PrivateRoute";
import Home from "./pages/Home";
import AccessDenied from "./pages/AccessDenied";
import AppLayout from "./components/AppLayout";
import Users from "./pages/Users";

function App() {
  return (
    <Router>
      <AppLayout>
        <Routes>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="/users" element={<Users />} />
          </Route>
          <Route path="/login" Component={Login} />
          <Route path="/access-denied" Component={AccessDenied} />
        </Routes>
      </AppLayout>
    </Router>
  );
}

export default App;
