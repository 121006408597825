import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

const PrivateRoute = () => {
  const location = useLocation(); // get the current location
  const { user, firebaseUser } = useAuth(); // determine if authorized, from context
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return firebaseUser && !user ? <></> : user ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location.pathname }} />
  );
};

export default PrivateRoute;
