import { Typography } from "@mui/material";
import React from "react";

const AccessDenied = () => {
  return (
    <div className="login-access-denied-container">
      <Typography variant="h3">Access Denied</Typography>
      <img
        src="https://storage.googleapis.com/magnesiumassets/you_shall_not_pass.png"
        width={300}
      />
    </div>
  );
};

export default AccessDenied;
