import { Typography } from "@mui/material";
import React from "react";
import "../styles/clickableCard.scss";
import Arrow from "../assets/icons/Arrow";
const ClickableCard = ({ record, title, handleOnClick, size = "small" }) => {
  return (
    <div
      onClick={(e) => handleOnClick(e, record)}
      className="clickable-card-container-root"
    >
      <div>
        <span className="clickable-card-module-text">{title}</span>
      </div>
      <div className="clickable-icon-container">
        <Arrow />
      </div>
    </div>
  );
};

export default ClickableCard;
